// core
import React from "react";
// components
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "components/spinner";
// template
import TemplateComponent from "react-mustache-template-component";
// templates
import TMPL from "templates";
import { add_to_favorites, remove_from_favorites } from "helpers/helpers";

export default function FacetResults({
  results,
  response,
  fetch_more,
  handle_click,
  uodate_result,
}) {
  const _handle_click = (evt, idx) => {
    // mange click on href
    // check for direct clicking on icon favorite
    if (evt.target.classList.contains("js-favorite")) {
      evt.stopPropagation();
      evt.preventDefault();
      const data = results[idx];
      const url = data["@id"];
      if (data.favorite === true) {
        remove_from_favorites(url).then((res) => {
          if (res === false) return;
          uodate_result(idx, false);
        });
      } else {
        add_to_favorites(url).then((res) => {
          if (res === false) return;
          console.log("★");
          uodate_result(idx, true);
        });
      }
    } else {
      const click_target = evt.target.closest("a");
      if (click_target.classList.contains("react-managed")) {
        evt.stopPropagation();
        evt.preventDefault();
        const ref = click_target.href;
        handle_click(ref);
      }
    }
  };

  const has_more = results.length < response.items_total;

  return (
    <div>
      <InfiniteScroll
        dataLength={results.length}
        next={fetch_more}
        hasMore={has_more}
        style={{ overflow: "hidden" }}
        loader={<Spinner />}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {results.map((item, index) => {
          // hack to force redraw
          const idx = index.toString() + "_" + item.favorite.toString();
          return (
            <TemplateComponent
              key={idx}
              template={TMPL.library_item__default}
              data={item}
              type="div"
              className="container"
              id={item.UID}
              onClick={(evt) => _handle_click(evt, index)}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}
