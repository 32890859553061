import TemplateComponent from "react-mustache-template-component";
import FormRender from "components/form_render";
import LibraryItemSmall from "components/homepage/library_item";
// templates
import TMPL from "templates";

function CollageFragment({ data, onTrigger }) {
  const item_type =
    (data["@type"] && data["@type"].toLowerCase().replace(" ", "_")) ||
    "undefined";
  const item_layout = data["collage_layout"] || "default";
  // need to be configured in .env for debug or pod for now just skip but i would like to redesign it
  if (item_type === "undefined") return <></>;
  const DEFAULT_TEMPLATE = `<pre>MISSING TEMPLATE "${item_layout}" for "${item_type}" </pre>`;
  const template = TMPL[item_type + "__" + item_layout] || DEFAULT_TEMPLATE;
  const tmpl_type = `${item_type}__${item_layout}`;

  const handle_click = (evt) => {
    // mange click on href
    if (
      evt.target.tagName.toLowerCase() === "a" &&
      evt.target.classList.contains("react-managed")
    ) {
      evt.stopPropagation();
      evt.preventDefault();
      const ref = evt.target.href;
      onTrigger(evt, tmpl_type, {
        action: "modal",
        url: ref,
      });
    }
  };
  let url = null;
  try {
    url = new URL(data.url);
  } catch (error) {
    console.error(error);
    window.$FRA = data;
  }

  switch (tmpl_type) {
    case "library_item__default":
      return (
        <LibraryItemSmall
          item={data}
          onClick={handle_click}
          highlighted={false}
        />
      );
    case "library_item__highlighted":
      return (
        <LibraryItemSmall
          item={data}
          onClick={handle_click}
          highlighted={true}
        />
      );
    case "easyform__default":
      return (
        <FormRender
          collage={true}
          path={url?.pathname}
          uid={data["@id"]}
          xmlschema={data.fields_model}
          title={data.title}
          pagedata={data}
          postsubmit_data={{
            showAll: data.showAll,
            showFields:
              data.showAll === true
                ? null
                : data.showFields.map((i) => i.token),
          }}
        />
      );
    default:
      return (
        <TemplateComponent
          onClick={handle_click}
          template={template}
          data={data}
        />
      );
  }
}

export default CollageFragment;
