const _root = process.env.REACT_APP_root || "";
const _library = process.env.REACT_APP_library || "";

const baseroute = process.env.REACT_APP_BASEROUTE;
export const conf = {
  baseroute: baseroute,
  portal: `${process.env.REACT_APP_PORTAL}`,
  endpoints: {
    root: `${_root}`,
    site: `${_root}/@site`,
    customersvocabulary: `${_root}/@vocabularies/tagetik.community.library.CustomersVocabulary?b_size=-1`,
    latest_assets: `${_root}/@querystring-search`,
    latest_qa: `${process.env.REACT_APP_qanews_url}`,
    login: `${process.env.REACT_APP_endpoints_login}`,
    userdata: `${process.env.REACT_APP_endpoints_userdata}`,
  },
  facets: [
    "asset_type",
    "regions",
    "file_language",
    "functional_topics",
    "technical_topics",
    "industries",
    "versions",
    "event_title",
    "technologies",
    "external_usage",
  ],
  facets_readable: {
    asset_type: "Asset Type",
    regions: "Regions",
    file_language: "File Language",
    functional_topics: "Functional Topics",
    technical_topics: "Technical Topics",
    industries: "Industries",
    versions: "Versions",
    event_title: "Event Title",
    technologies: "Technologies",
    external_usage: "External usage",
  },
  ajax_configuration_object: {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  },
  redirect: {
    home_page: `${process.env.REACT_APP_home_page}`,
    menu_urls: ["marketing", "sales", "product", "training", "legal"],
  },
  library: {
    title: "Library Page",
    page: `${_library}`,
    skeleton_query: `${_library}/@faceted-search?facets_only=true`,
    endpoint: `${_library}/@faceted-search`,
    results: {
      page_size: 15,
    },
  },
  route: {
    navigation: `${baseroute}:section/*`,
    navigation_end: `${baseroute}:section/`,
    library: `${baseroute}library/*`,
    library_item: `${baseroute}library/:item`,
    library_item_from_uid: `${baseroute}goto/:uid`,
    embedded: `${baseroute}embed/*`,
    base: `${baseroute}`,
    home_page: `${baseroute}/home-page/*`,
  },
  excluded_from_nav: process.env.REACT_APP_excluded_folders?.split("|") || [],
  app: {
    app_type: process.env.REACT_APP_TYPE,
    export_symbols: process.env.REACT_APP_EXPORT_SYMBOLS,
    link_replace_pattern: {
      from: `${_root}/`,
      to: baseroute,
    },
  },
  matomo: {
    track_id: process.env.REACT_APP_MATOMO_TRACK_ID,
  },
  site_title: 'CCH Tagetik Community'
};

conf.endpoints.login = conf.endpoints.login.replace(
  "{from}",
  encodeURI(process.env.REACT_APP_endpoints_came_from),
);
if (conf.app.export_symbols) window.$CONF = conf;

export const library_portal_type_query = {
  i: "portal_type",
  o: "plone.app.querystring.operation.selection.any",
  v: ["Library Item"],
};

export const queries = {
  latest_assets: {
    limit: 3,
    sort_on: "upload_date",
    sort_order: "descending",
    fullobjects: true,
    query: [library_portal_type_query],
  },
};

export const modalStyle = {
  content: {
    maxWidth: "60%",
    minWidth: "60%",
    maxHeight: "80%",
    minHeight: "80%",
    height: "80%",
    //    overflowY: 'hidden',
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

window.conf = conf;
