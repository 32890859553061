// core
import React from "react";

export default function ErrorMessage({ error }) {
  let msg = "";
  switch (error.message.toLowerCase()) {
    case "unauthorized":
      msg = "You are not authorized to access this resource";
      break;
    case "not found":
      msg = "page not found";
      break;
    default:
      msg = "there is an error";
  }

  return (
    <div className="error_wrapper">
      <p>{msg}</p>
    </div>
  );
}
