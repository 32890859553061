// conf
import { conf, who_need_fullobjects } from "conf/plone";
// react library
import { useLocation } from "react-router-dom";
import { useFetch } from "usehooks-ts";
import { useContext, useEffect } from "react";
// app components
import BreadCumbs from "components/breadcrumbs";
import PathBar from "components/pathbar";
import Page from "components/page";
import Listing from "components/listing";
import FormRender from "components/form_render";
import Spinner from "components/spinner";
import CollagePage from "components/collage/collage_page";
import PageItem from "components/facet/item_page";
import PressList from "components/press/press_list";
import PressItemFull from "components/press/press_item";
import FileView from "components/file_view";
import ErrorMessage from "components/error_message";
// context
import SiteContext from "store/site";

// a dynamic components, wrapper for many widgets
export function DynPageComponent({ type, layout, data, path }) {
  const title = data.title;
  const { matomo } = useContext(SiteContext);
  useEffect(() => {
    matomo.track_pageview(path, title);
  }, [matomo, path, title]);

  function DefaultError() {
    const msg = `componet not found for type ${type}, layout ${layout}`;
    return <Page title="missing component" text={msg} path={path} />;
  }
  switch (type) {
    case "File":
      switch (layout) {
        case "file_view":
          return (
            <FileView
              title={data.title}
              target={data.targetUrl}
            />
          );
        default:
          return <DefaultError />;
      }
    case "Homepage":
      switch (layout) {
        case "view":
          return (
            <Page
              title={data.title}
              text={data.text}
              path={path}
              fullwidth={true}
            />
          );
        default:
          return <DefaultError />;
      }
    case "Library Item":
      switch (layout) {
        case "view":
          return <PageItem url={path} />;
        default:
          return <DefaultError />;
      }
    case "Document":
      switch (layout) {
        case "document_view":
          return <Page title={data.title} text={data.text} path={path} />;
        default:
          return <DefaultError />;
      }
    case "Collage":
      switch (layout) {
        case "view":
          return <CollagePage path={path} title={data.title} data={data} />;
        default:
          return <DefaultError />;
      }
    case "Press Release":
      switch (layout) {
        case "view":
          return <PressItemFull data={data} />;
        default:
          return <DefaultError />;
      }
    case "News Item":
      switch (layout) {
        case "newsitem_view":
          return (
            <Page title={data.title} text={data.text} date={data.effective} />
          );
        default:
          return <DefaultError />;
      }
    case "Plone Site":
      return (
        <Listing
          items={data.items.filter((i) => i.review_state === "published")}
          title={data.title}
        />
      );
    case "Folder":
      if (path.startsWith("/news")) {
        return <PressList data={data} />;
      }
      switch (layout) {
        case "listing_view":
          return (
            <Listing
              items={data.items.filter((i) => i.review_state === "published")}
              title={data.title}
            />
          );
        default:
          return <DefaultError />;
      }
    case "EasyForm":
      return (
        <FormRender
          path={path}
          uid={data["@id"]}
          xmlschema={data.fields_model}
          title={data.title}
          pagedata={data}
          postsubmit_data={{
            showAll: data.showAll,
            showFields:
              data.showAll === true
                ? null
                : data.showFields.map((i) => i.token),
          }}
        />
      );
    default:
      return <DefaultError />;
  }
}

// like dynpage but with ability to loads its contents
function InsidePage({ url, path }) {
  const { data, error } = useFetch(url, conf.ajax_configuration_object);
  if (error) return <p>There is an error.</p>;
  if (!data) return <Spinner />;
  return (
    <DynPageComponent
      type={data["@type"]}
      layout={data.layout}
      data={data}
      path={path}
    />
  );
}

function PublishingRestrictions({ profiles }) {
  if (profiles.length > 0) {
    return (
      <div className="container container-fake_side_column">
        <div></div>
        <div>
          <div className="container flex x-right y-center" style={{ marginBottom: 0 }}>
            <div><small style={{'white-space': 'nowrap'}}><strong>Publishing restrictions.</strong> This item is published for:</small></div>
            <div><small>
              <ul className="inline">
                {profiles.map((item, index) => (
                  <li><span className="pill" key={index}>{item.title}</span></li>
                ))}
              </ul>
            </small></div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

// base layout pager
export default function Main(props) {
  const history = useLocation();
  const _pathname = history.pathname.replaceAll(conf.route.base, "/");
  let _uri = `${conf.endpoints.root}${_pathname}?expand=breadcrumbs,defaultpage,publication_policy`;

  if (_pathname.startsWith("/news")) {
    _uri += "&fullobjects=1";
  }
  const { data, error } = useFetch(_uri, conf.ajax_configuration_object);
  if (error) return <ErrorMessage error={error} />
  if (!data) return <Spinner />;

  const defaultpage = data["@components"]?.defaultpage?.page || null;
  const inside_url =
    defaultpage !== null
      ? `${conf.endpoints.root}${_pathname}/${defaultpage}?expand=publication_policy`
      : null;
  const profiles = data['@components']?.publication_policy?.profiles || [];
  return (
    <main>
      <BreadCumbs breadcumbs={data["@components"]?.breadcrumbs} />
      {data["@type"] !== 'Library Item' ? <PublishingRestrictions profiles={profiles} /> : <></>}
      <div className="container container-side_column">
      {data["@type"] !== 'Library Item' ? <PathBar url={_pathname} /> : <div></div>}
        {defaultpage === null && (
          <>
            <DynPageComponent
              type={data["@type"]}
              layout={data.layout}
              data={data}
              path={_pathname}
            />
          </>)}
        {defaultpage !== null && (
          <>
            <InsidePage url={inside_url} path={`${_pathname}/${defaultpage}`} />
          </>
        )}
      </div>
    </main>
  );
}
