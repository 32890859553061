// style
import "scsss/app.scss";
// react core
import { Routes, Route } from "react-router-dom";
// custom components
import Main from "layouts/main";
import Library from "layouts/library";
import Spinner from "components/spinner";
import Redirector from "components/redirector";
import UIDLOADER from "components/uid_loader";
import HomePage from "layouts/home_page";
import Embed from "components/embedded/embed";
// conf
import { conf } from "conf/plone";
// hooks
import { useEffect, useState } from "react";

// store
import SiteContext from "store/site";

// matomo
import TagetikMatomo from "tagetik-matomo";

function App()
{
  const [user_data, set_user_data] = useState(null);

  useEffect(() =>
  {
    const post_options = Object.assign({}, conf.ajax_configuration_object);
    post_options.method = "POST";
    fetch(conf.endpoints.userdata, post_options).then((res) =>
    {
      if (res.ok)
      {
        res
          .json()
          .then((json) =>
          {
            set_user_data(json);
          })
          .catch((err) =>
          {
            set_user_data({ status: "error", errorcode: err });
          });
      } else set_user_data({ status: "error", errorcode: res.status });
    });
  }, []);

  if (!user_data) return <Spinner />;

  if (user_data.status === "error") return <p>There is an error.</p>;

  if (user_data.status === "Unknown")
    return (
      <p>
        <Redirector url={conf.endpoints.login} />
      </p>
    );

  //const matomo_instance = new TageetikMatomo();
  const matomo_instance = new TagetikMatomo(
    conf.matomo.track_id,
    user_data.username,
    user_data.profile,
    user_data.role,
    true,
  );
  matomo_instance.init();
  matomo_instance.disable_log();

  return (
    <SiteContext.Provider
      value={{ base: conf.baseroute, user: user_data, matomo: matomo_instance }}
    >
      <div>
        <Routes>
          <Route path={conf.route.navigation} element={<Main />} />
          <Route path={conf.route.library} element={<Library />} />
          <Route path={conf.route.library_item} element={<Main />} />
          <Route path={conf.route.library_item_from_uid} element={<UIDLOADER />} />
          <Route path={conf.route.embedded} element={<Embed />} />
          <Route path={conf.route.base} element={<HomePage />} />
          <Route path={conf.route.home_page} element={<HomePage />} />
        </Routes>
      </div>
    </SiteContext.Provider>
  );
}

export default App;
